import React from 'react';
import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import { Link } from 'react-router-dom';
import aboutImg from '../../assets/images/profile.jpg';
 
class MyBackground extends React.Component {
    state = {
        isOpen: false
    }

    openModal = () => {
        this.setState({isOpen: true})
    }
    render(){
        return (
            <section id="background" className="about-area bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h2>My <span>Background</span></h2>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-md-12">
                            <div className="about-video">
                                <img src={aboutImg} alt="about" />
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details">
                            <div className="article-text">
                            <p>I received my <strong>Masters in Counseling at St. Edward's University</strong> in Austin, Texas with a specialty in <strong>Marriage and Family Therapy</strong> and my <strong>Bachelor's degree in Psychology</strong> at ASU. I have trained and worked with adults, couples, adolescents, and families struggling with a wide range of issues at several counseling agencies, non-profit organizations and clinical private practices in Austin. Throughout the years, I have practiced as a therapist at Lifeworks, Capital Area Counseling, Relationship Counseling Center of Austin, Restored Family Counseling and I'm currently working in a private practice setting at <strong>Insight Therapy Center</strong>.</p>

<p>I'm an active member of the <strong>American Psychological Association</strong>, <strong>Austin IN (Interpersonal Neurobiology) Connection</strong> and an engaged member of the psychotherapy community in Austin. Professional development is very important to me and I regularly attend conferences and advanced training seminars to access the latest evidence-based therapy techniques and provide the highest quality counseling services.  I am currently engaged in <strong>ongoing training</strong> in <strong>advanced state-of-the-art models</strong> and <strong>neuro-science informed methods</strong> that implement systemic and interpersonal approaches to help individuals, couples and families <strong>overcome challenges and reach their ideal outcome.</strong></p>
<p>I have always been drawn to the process of helping others through authentic conversation and connection. I became a therapist with the intention of offering those in need a safe space where they can be fully themselves and a chance to explore deeper into their inner world. I am <strong>passionate about my work as a psychotherapist</strong> and feel very honored to accompany clients in working through their struggles, creating positive and meaningful change, meeting their goals, and helping them thrive. <strong>A life that feels free, fulfilling and truly authentic!</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default MyBackground;