import React from 'react';
import { Link } from 'react-router-dom';
import OnlineBanner from '../PageBanners/OnlineBanner';
import image from "../../assets/images/blog-details.jpg";
import clientImageOne from "../../assets/images/client-avatar1.jpg";
import clientImageTwo from "../../assets/images/client-avatar2.jpg";
import clientImageThree from "../../assets/images/client-avatar3.jpg";
import Footer from '../Common/Footer';
import GoTop from '../Common/GoTop';
import ScheduleAppointment from '../Common/ScheduleAppointment';
import Helmet from 'react-helmet';
import SubpagePricing from '../Common/SubpagePricing';
 
class Online extends React.Component {
    render(){
        return (
            <React.Fragment>
                <Helmet>
                    <title>Insight Therapy Center | Online Counseling | Counseling & Psychotherapy | Austin, TX | Mirela Bitkowski, LPC</title>
                </Helmet>
                <OnlineBanner />

                <section className="blog-details-area ptb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-12 offset-lg-2">
                                <div className="blog-details">
                                    <div className="article-text">
                                        <h4 className="title">Online Counseling</h4>
                                        <p>For clients that are traveling or living in other cities, it can be easier to conduct sessions online. For online counseling, we will be using a HIPAA compliant platform to communicate – Doxy.me. Online counseling clients that are living within the United Stated must reside in Texas.</p>
                                        <p>For international clients, please contact me directly to address the specific laws in your country related to online counseling. The sessions are conducted in private and headphones are necessary for every session.</p>
                                        {/* <SubpagePricing /> */}
                                        <p>When you're ready to begin therapy, fill out the information in "Schedule Now" and I will be contacting you soon to set up an appointment. I look forward to hearing from you!</p>
                                        <p><i><strong>We must let go of the life we have planned, so as to accept the one that is waiting for us.</strong> - Joseph Campbell</i></p><br/>
                                        <div className="center"><ScheduleAppointment /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <GoTop scrollStepInPx="50" delayInMs="16.66" />
            </React.Fragment>
        );
    }
}
 
export default Online;