import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Navigation from './components/Navigation/Navigation';
import Preloader from './components/Common/Preloader';
import HomeOne from '../src/components/Pages/HomeOne';
import HomeTwo from '../src/components/Pages/HomeTwo';
import HomeThree from '../src/components/Pages/HomeThree';
import HomeFour from '../src/components/Pages/HomeFour';
import HomeFive from './components/Pages/HomeFive';
import HomeSix from './components/Pages/HomeSix';
import HomeSeven from './components/Pages/HomeSeven';
import HomeEight from './components/Pages/HomeEight';
import HomeNine from './components/Pages/HomeNine';
import HomeTen from './components/Pages/HomeTen';
import HomeEleven from './components/Pages/HomeEleven';
import HomeTwelve from './components/Pages/HomeTwelve';
import Blog from './components/Pages/Blog';
import BlogDetails from './components/Pages/BlogDetails';
import Individual from './components/Pages/Individual';
import Family from './components/Pages/Family';
import Couples from './components/Pages/Couples';
import EMDR from './components/Pages/EMDR';
import Online from './components/Pages/Online';

class App extends React.Component {

    state = {
        loading: true
    };

    componentDidMount(){
        this.demoAsyncCall().then(() => this.setState({ loading: false }));
    }

    demoAsyncCall = () => {
        return new Promise((resolve) => setTimeout(() => resolve(), 2000));
    }

    hashLinkScroll = () => {
        const { hash } = window.location;
        if (hash !== '') {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) element.scrollIntoView();
            }, 0);
        }
    }

    render() {
        return (
            <Router onUpdate={this.hashLinkScroll}>
                <React.Fragment>
                    {this.state.loading ? <Preloader /> : ''}
                    <Navigation />
                    <Route path="/" exact component={HomeOne} />
                    <Route path="/home-two" exact component={HomeTwo} />
                    <Route path="/individual" exact component={Individual} />
                    <Route path="/family" exact component={Family} />
                    <Route path="/couples" exact component={Couples} />
                    <Route path="/emdr" exact component={EMDR} />
                    <Route path="/online" exact component={Online} />
                    {!this.state.loading ? window.scrollTo(0, 0) : ''}
                </React.Fragment>
            </Router>
        );
    }
}

export default App;
