import React from 'react';
import { Link } from "react-router-dom";
import ScheduleAppointment from '../Common/ScheduleAppointment';
import GoogleMapReact from 'google-map-react';
import 'isomorphic-fetch';

const CustomComponent = ({ text }) => <div><img src="https://ruralshores.com/assets/marker-icon.png" alt="map" /></div>;
 
class Contact extends React.Component {
    static defaultProps = {
        center: {
            lat: 30.274431,
            lng: -97.816954
        },
        zoom: 11
    };

    state = {
        submitting: false,
        submitted: false,
        buttonState: '',
        formFields: {
            name: '',
            email: '',
            phone: '',
            text: ''
        }
    };

    onSubmit = (e) => {
        e.preventDefault();
        const data = this.state.formFields;
        fetch('/api/contact', {
            method: 'post',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => {
            if (res.status === 200) {
                this.setState({ submitted: true })
            }
            let formFields = Object.assign({}, this.state.formFields);
            formFields.name = '';
            formFields.email = '';
            formFields.phone = '';
            formFields.text = '';
            this.setState({formFields});
        });
    }

    nameChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.name = e.target.value;
        this.setState({formFields});
    }

    emailChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.email = e.target.value;
        this.setState({formFields});
    }

    phoneChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.phone = e.target.value;
        this.setState({formFields});
    }

    textChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.text = e.target.value;
        this.setState({formFields});
    }

    onHideSuccess = () => {
        this.setState({submitted: false});
    }

    renderMarkers(map, maps) {
        let marker = new maps.Marker({
          position: { lat: 30.289240, lng: -97.818270 },
          map,
          title: 'Insight Therapy Center'
        });
      }

    successMessage = () => {
        if (this.state.submitted){
            return (
                <div 
                    className="alert alert-success alert-dismissible fade show" 
                    style={{ marginTop: '14px' }}
                >
                    <strong>Thank you!</strong> Your message is send to the owner.
                    <button 
                        type="button" 
                        className="close"
                        onClick={this.onHideSuccess}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            );
        }
    }

    render(){
        return (
            <section id="contact" className="contact-area ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h2><span>Pricing</span> &amp; <span>Location</span></h2>
                    </div>
                    
                    <div className="row">

                        <div className="col-lg-6 col-md-12">
                            <div className="contact-form">
                                {/* <p><strong>15 min Phone Consultation - Free</strong> to discuss your needs, your goals for therapy and how I can help.</p><br/> */}
                                <p><strong>50 min session - $140</strong> for Individual, Couple and Family Therapy</p><br/>
                                {/* <p><strong>50 min session, Student Rate - $100</strong> // These sessions are offered at a lower rate for currently enrolled students. Client will need to provide some proof of current school enrollment when scheduling their first session.</p><br/> */}
                                {/* <p><strong>Online sessions available</strong> - priced the same as in-person sessions</p><br/> */}

{/* <p>I also offer a sliding scale fee based on the client's circumstance and income. Please email me to inquire about sliding scale.</p> */}
<p><strong>I accept Aetna, Lyra Health, Blue Cross Blue Shield, United Healthcare, and Optum PPO insurance.</strong>  Please verify your health insurance coverage when you arrange your first visit.  I can also provide a superbill for "out-of-network" insurance reimbursement.</p><br/>
<p><strong>Cancelation and "No Show" Policy - </strong>Please note that I require 24 hours notice for canceling or rescheduling an appointment.  If canceling within less than 24 hours, the client will be charged the full session fee.  No exceptions will be made.</p><br/>
{/* <p><strong>Mentorship Consultation - 45 min consultation - $80</strong> // for aspiring therapists, current therapists &amp; wellness entrepreneurs.</p> */}

                                <ScheduleAppointment/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div id="map">
                                <div style={{ height: '100%', width: '100%' }}>
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: 'AIzaSyDWVTU7Bw8S8R6itljxPhp-1Xr3TQn7JZs' }}
                                        defaultCenter={this.props.center}
                                        defaultZoom={this.props.zoom}
                                        onGoogleApiLoaded={({map, maps}) => this.renderMarkers(map, maps)}
                                    >
                                    </GoogleMapReact>
                                </div>
                            </div>
                            
                            <div className="contact-info">
                                <ul>
                                    <li>
                                        <i className="fa fa-map-marker"></i>Austin, TX
                                    </li>
                                    <li>
                                        <i className="fa fa-envelope"></i> 
                                        <Link to="#">mirela@insight-therapycenter.com</Link>
                                    </li>
                                    <li>
                                        <i className="fa fa-phone"></i> 
                                        <Link to="#">(512) 957-4527</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        

                    </div>
                </div>
            </section>
        );
    }
}
 
export default Contact;