import React from 'react';
import { Link } from 'react-router-dom';
import FamilyBanner from '../PageBanners/FamilyBanner';
import image from "../../assets/images/blog-details.jpg";
import clientImageOne from "../../assets/images/client-avatar1.jpg";
import clientImageTwo from "../../assets/images/client-avatar2.jpg";
import clientImageThree from "../../assets/images/client-avatar3.jpg";
import Footer from '../Common/Footer';
import GoTop from '../Common/GoTop';
import ScheduleAppointment from '../Common/ScheduleAppointment';
import Helmet from 'react-helmet';
import SubpagePricing from '../Common/SubpagePricing';
 
class Family extends React.Component {
    render(){
        return (
            <React.Fragment>
                 <Helmet>
                    <title>Insight Therapy Center | Children, Teens, and Family Therapy | Counseling & Psychotherapy | Austin, TX | Mirela Bitkowski, LPC</title>
                </Helmet>
                <FamilyBanner />

                <section className="blog-details-area ptb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-12 offset-lg-2">
                                <div className="blog-details">
                                    <div className="article-text">
                                        <h4 className="title">Children, Teens &amp; Family Therapy</h4>
                                        
                                        <p>As children and adolescents are continually growing and changing, so are the needs of developing social skills and emotional intelligence that are crucial and necessary to lead happy and healthy lives. In therapy, children and adolescents can talk about whatever issues they are facing. They can also learn ways to manage their emotions and practice ways to engage with the world in a safe and collaborative space, in which all of their feelings and emotions are welcomed in the room.</p>

                                        <p>The goal in therapy is to assist the child or teenager in developing a strong sense of identity that is rooted in honesty, compassion, and self-responsibility. As a therapist, I will be working with the child/adolescent to help them learn how to express emotions, increase their self-confidence, improve communication with family members, define their values and roles, establish boundaries and gain skills to set them up for success at home, in school and in social situations.</p>

                                        <p>Parenting is one of the most difficult, challenging, as well as rewarding aspects of a person's life. I am also a Certified Positive Discipline Parent Educator, which promotes healthy communication, boundaries and autonomy between all family members and teaches valuable social and life skills. Sometimes parents can feel overwhelmed in trying to attend to their child / adolescent's daily needs. Consequently, parents may find therapy particularly helpful when faced with a challenging situation or behavioral concern.</p>
                                        {/* <SubpagePricing /> */}
                                        <p>When you're ready to begin therapy, fill out the information in "Schedule Now" and I will be contacting you soon to set up an appointment. I look forward to hearing from you!</p>
                                        <p><i><strong>When we begin to know ourselves in an open and self-supportive way, we take the first step to encourage our children to know themselves.</strong> - Daniel J. Siegel</i></p><br/>
                                        <div className="center"><ScheduleAppointment /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <GoTop scrollStepInPx="50" delayInMs="16.66" />
            </React.Fragment>
        );
    }
}
 
export default Family;