import React from 'react';
import { Link } from 'react-router-dom';
 
class Welcome extends React.Component {
    render(){
        return (
            <section id="welcome" className="welcome-area ptb-80">
                <div className="container">
                    <div className="section-title">
                        
                        <h2><span>Welcome</span></h2>
                        
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="blog-details">
                                    <div className="article-text">
                                    <p>Hello and welcome! <strong>I’m Mirela and I'm so glad that you are here!</strong> Whether you are going through a difficult time or looking for some support, I am here to help you develop the necessary insights and tools you need to achieve clarity and healing. As a licensed psychotherapist, I believe that we can all benefit from learning more about ourselves in a safe, compassionate and collaborative environment. Whatever you are experiencing right now, know that you don't have to go through it alone. I believe that you - whoever you are - have the capacity to lead a happy, authentic, and fulfilling life.</p>

<p>I often work with:</p>
<ul>
<li>Adult individuals who are interested in their own <strong>personal growth</strong>, who wish to find <strong>fulfillment</strong> in life, overcome <strong>anxiety</strong>, <strong>depression</strong>, <strong>self-esteem issues</strong>, and <strong>relationship struggles</strong>.</li>
<li>Couples who wish to <strong>strengthen their relationship</strong>, <strong>deepen their connection</strong>, <strong>resolve conflict</strong>, <strong>increase intimacy</strong> and <strong>improve communication</strong> patterns.</li>
<li>Children and adolescents (ages 10 and over) who wish to learn to <strong>navigate change</strong>, <strong>manage emotions</strong> and <strong>practice engaging with the world</strong> in a safe and collaborative space.</li>
<li>I've worked with many people, of many ages, from <strong>all different walks of life</strong> and another area of expertise for me is in the realm of <strong>diversity factors and cultural struggles</strong>. I also enjoy working closely with <strong>expats</strong> and/or <strong>internationals</strong> from all over the world.</li>
<li><strong>Teletherapy based in Austin, available throughout the State of Texas.</strong> Offering appointments over HIPAA compliant videoconferencing platforms.</li>
</ul>
<p>In our collaborative work and journey together, I will focus not only on investigating the root of your struggles, but also understand how they are impacting you today, and look at what changes can be made to help you live a healthy, productive, and harmonious life. In this therapeutic process, you will have the opportunity to explore and uncover emotional barriers and heal past painful experiences. We will focus on developing better coping skills, explore ways to feel more empowered and figure out limiting behaviors and thought patterns that are standing in the way of achieving your goals.</p>

<p>I am very curious to learn more about your story and I'm grateful that you are joining me on this incredible adventure!</p>
<br/>
<p className="center">Mirela Bitkowski, LPC<br/>
<span style={{fontSize: "85%"}}>Licensed Professional Counselor</span><br/>
<span style={{fontSize: "85%"}}>Clinical Psychotherapist</span></p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Welcome;