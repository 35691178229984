import React from 'react';
import { Link } from 'react-router-dom';
import ScheduleAppointment from './ScheduleAppointment';
 
class Strategy extends React.Component {
    render(){
        return (
            <section className="strategy-area">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-5">
                            <div className="image"></div>
                        </div>
                        
                        <div className="col-lg-6 col-md-7">
                            <div className="about-strategy ptb-80">
                                <div className="section-title">
                                    <h2>I Also <span>Focus</span> On</h2>
                                </div>
                                
                                <ul>
                                    <li><i className="fa fa-check"></i>anxiety & stress management</li>
                                    <li><i className="fa fa-check"></i>coping skills</li>
                                    <li><i className="fa fa-check"></i>life transitions</li>
                                    <li><i className="fa fa-check"></i>depression</li>
                                    <li><i className="fa fa-check"></i>relationship issues</li>
                                    <li><i className="fa fa-check"></i>premarital counseling & marital issues</li>
                                    <li><i className="fa fa-check"></i>family concerns</li>
                                    <li><i className="fa fa-check"></i>creating healthy lifestyle habits</li>
                                    <li><i className="fa fa-check"></i>finding fulfillment in life</li>
                                    <li><i className="fa fa-check"></i>managing overwhelming emotions</li>
                                    <li><i className="fa fa-check"></i>self-esteem & personal empowerment</li>
                                    <li><i className="fa fa-check"></i>finding work-life balance</li>
                                    <li><i className="fa fa-check"></i>family of origin issues</li>
                                    <li><i className="fa fa-check"></i>inner-child healing</li>
                                    <li><i className="fa fa-check"></i>boundary setting</li>
                                    <li><i className="fa fa-check"></i>multicultural issues</li>
                                    <li><i className="fa fa-check"></i>trauma recovery</li>

                                </ul>
                                <ScheduleAppointment />
                            </div>
                            
                        </div>
                        
                    </div>
                    
                </div>
                
            </section>
        );
    }
}
 
export default Strategy;