import React from 'react';
import { Link } from 'react-router-dom';
import EMDRBanner from '../PageBanners/EMDRBanner';
import image from "../../assets/images/blog-details.jpg";
import clientImageOne from "../../assets/images/client-avatar1.jpg";
import clientImageTwo from "../../assets/images/client-avatar2.jpg";
import clientImageThree from "../../assets/images/client-avatar3.jpg";
import Footer from '../Common/Footer';
import GoTop from '../Common/GoTop';
import ScheduleAppointment from '../Common/ScheduleAppointment';
import Helmet from 'react-helmet';
import SubpagePricing from '../Common/SubpagePricing';
 
class EMDR extends React.Component {
    render(){
        return (
            <React.Fragment>
                <Helmet>
                    <title>Insight Therapy Center | EMDR Therapy | Counseling & Psychotherapy | Austin, TX | Mirela Bitkowski, LPC</title>
                </Helmet>
                <EMDRBanner />

                <section className="blog-details-area ptb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-12 offset-lg-2">
                                <div className="blog-details">
                                    <div className="article-text">
                                        <h4 className="title">EMDR Therapy</h4>
                                        
                                        <p>Eye-Movement Desensitization Reprocessing (EMDR), is a type of therapy that works well when your brain finds an experience (or set of experiences) too overwhelming and interferes with or limits your ability to function in, and enjoy, your daily life. EMDR can help you reset how you accept and deal with the associated recollections and feelings. EMDR treatment can help both your mind and body to change your perceptions and narratives of past trauma, and remove triggers of your painful memories. EMDR works well to overcome symptoms associated with trauma, anxiety, depression, phobias, panic attacks, performance anxiety, sleep disturbance and more.</p>

                                        <p>Repeated studies show that by using EMDR therapy people can experience the benefits of psychotherapy that once took years to make a difference. It is widely assumed that severe emotional pain requires a long time to heal.  EMDR processing helps you break through the emotional blocks that are keeping you from living an adaptive, emotionally healthy life.</p>
                                        <p>I am EMDR trained and offer this therapy modality for those that are interested.  For more information about EMDR, please visit the link at <a target="_blank" href="https://www.emdria.org/page/what_is_emdr_therapy">EMDRIA</a></p>
                                        {/* <SubpagePricing /> */}
                                        <p>When you're ready to begin therapy, fill out the information in "Schedule Now" and I will be contacting you soon to set up an appointment. I look forward to hearing from you!</p>
                                        <p><i><strong>Owning our story and loving ourselves through that process is the bravest thing that we will ever do.</strong> - Brené Brown</i></p><br/>
                                        <div className="center"><ScheduleAppointment /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <GoTop scrollStepInPx="50" delayInMs="16.66" />
            </React.Fragment>
        );
    }
}
 
export default EMDR;