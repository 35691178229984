import React from 'react';
import { Link } from 'react-router-dom';
import imgOne from '../../assets/images/individual.jpg';
import imgTwo from '../../assets/images/family.jpg';
import imgThree from '../../assets/images/couples.jpg';
import imgFour from '../../assets/images/EMDR.jpg';
import imgFive from '../../assets/images/online.jpg';
 
class Services extends React.Component {
    render(){
        return (
            <section id="services" className="services-area">
                <div className="container">
                    <div className="section-title">
                        <h2><span>Services</span></h2>
                    </div>
                
                    <div className="row display-flex justify-content-md-center">
                        <div className="d-flex flex-column col-lg-4 col-md-6">
                            <div className="single-services">
                                <div className="services-img">
                                    <img src={imgOne} alt="services-img" />
                                </div>
                                
                                <div className="services-content">
                                    <h3>Individual Therapy</h3>
                                    <p>The relationship you have with yourself is very important and sets the tone for every other relationship you have. Establishing a healthy and positive relationship with yourself can play a significant part in having happy and fulfilling relationships and connections with the people in your life. In individual therapy, people can overcome obstacles to their overall well-being.</p>
                                </div>
                                <Link to="/individual" className="btn btn-primary">More Info</Link>
                            </div>
                        </div>
                        
                        <div className="d-flex flex-column col-lg-4 col-md-6">
                            <div className="single-services">
                                <div className="services-img">
                                    <img src={imgTwo} alt="services-img" />
                                </div>
                                
                                <div className="services-content">
                                    <h3>Children, Teens &amp; Family Therapy</h3>
                                    <p>As children and adolescents continually grow and change, they need to develop crucial social skills and emotional intelligence in order to lead happy and healthy lives. In therapy, children and adolescents can learn ways to manage their emotions and practice engaging with the world in a safe and collaborative space where all feelings and emotions are welcomed.</p>
                                </div>
                                <Link to="/family" className="btn btn-primary">More Info</Link>
                            </div>
                        </div>
                        
                        <div className="d-flex flex-column col-lg-4 col-md-6">
                            <div className="single-services">
                                <div className="services-img">
                                    <img src={imgThree} alt="services-img" />
                                </div>
                                
                                <div className="services-content">
                                    <h3>Couples Therapy</h3>
                                    <p>Relationships are not optional, they are at the center of life! Learning to develop and sustain positive and happy relationships is critical to our health, growth and fulfillment. Couples therapy provides a safe space for partners to express their feelings, learn new healthy ways of communicating, improve intimacy, resolve conflict, and tackle life's challenges in a supportive and accepting environment.</p>
                                </div>
                                <Link to="/couples" className="btn btn-primary">More Info</Link>
                            </div>
                        </div>
                        
                        <div className="d-flex flex-column col-lg-4 col-md-6">
                            <div className="single-services">
                                <div className="services-img">
                                    <img src={imgFour} alt="services-img" />
                                </div>
                                
                                <div className="services-content">
                                    <h3>EMDR Therapy</h3>
                                    <p>Eye-Movement Desensitization Reprocessing (EMDR), is a type of therapy that works well when your brain finds an experience (or set of experiences) too overwhelming and interferes with or limits your ability to function in, and enjoy, your daily life. EMDR can help you reset how you accept and deal with the associated recollections and feelings. </p>
                                </div>
                                <Link to="/emdr" className="btn btn-primary">More Info</Link>
                            </div>
                        </div>
                        
                        <div className="d-flex flex-column col-lg-4 col-md-6">
                            <div className="single-services">
                                <div className="services-img">
                                    <img src={imgFive} alt="services-img" />
                                </div>
                                
                                <div className="services-content">
                                    <h3>Online Counseling</h3>
                                    <p>For clients that are traveling or living in other cities, it can be easier to conduct sessions online. For online counseling, we will be using a HIPAA compliant platform to communicate– Doxy.me. Online counseling clients that are living within the United Stated must reside in Texas.</p>
                                </div>
                                <Link to="/online" className="btn btn-primary">More Info</Link>
                            </div>
                        </div>
                        
                        
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Services;