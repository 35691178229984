import React from 'react';
import { Link } from 'react-router-dom';
import CouplesBanner from '../PageBanners/CouplesBanner';
import image from "../../assets/images/blog-details.jpg";
import clientImageOne from "../../assets/images/client-avatar1.jpg";
import clientImageTwo from "../../assets/images/client-avatar2.jpg";
import clientImageThree from "../../assets/images/client-avatar3.jpg";
import Footer from '../Common/Footer';
import GoTop from '../Common/GoTop';
import ScheduleAppointment from '../Common/ScheduleAppointment';
import Helmet from 'react-helmet';
import SubpagePricing from '../Common/SubpagePricing';
 
class Couples extends React.Component {
    render(){
        return (
            <React.Fragment>
                <Helmet>
                    <title>Insight Therapy Center | Couples Therapy | Counseling & Psychotherapy | Austin, TX | Mirela Bitkowski, LPC</title>
                </Helmet>
                <CouplesBanner />

                <section className="blog-details-area ptb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-12 offset-lg-2">
                                <div className="blog-details">
                                    <div className="article-text">
                                        <h4 className="title">Couples Therapy</h4>
                                        
                                        <p>Relationships are not optional, they are at the center of life! Learning to develop and sustain positive, safe, relationships is critical to our health, growth and fulfillment. Couples therapy provides a safe space for partners to express their feelings, gain insight into the relational dynamics, learn new healthy ways of communicating, improve intimacy, resolve conflict, repair trust, and tackle life's challenges in a supportive, safe and collaborative environment. Couples can also enhance their relationship in therapy by connecting on a deeper level while learning new tools and skills to apply especially when coping with stressful events.</p>
                                        <p>Gaining insight into the relationship dynamics is very important; however, another crucial aspect of couples therapy involves actually changing behaviors and ways of interacting with each other. It's important that couples apply the skills they have learned in therapy to their daily interactions in order to achieve their desired goals. Most couples can come away from couples therapy having gained awareness into relational patterns, increased emotional expression, strengthened their connection, and developed the tools necessary to communicate and problem-solve with their partners more effectively.</p>
                                        <p>I am confident that we can work together to improve quickly and in profound ways that will deepen your intimacy and connection, improve communication and ways of relating with each other. You will also learn new skills and tools and have the opportunity to practice different experiential exercises to help increase safety, understanding and awareness. I incorporate Gottman Method of Couples Therapy in my work with relationships and I'm currently training in advanced state-of-the-art models and neuro-science informed methods that implement systemic and interpersonal approaches to help couples experience a closer, more secure, vibrant and thriving relationship.</p> 
                                        <p>When you're ready to begin couples therapy, fill out the information in "Schedule Now" and I will be contacting you soon to set up an appointment. I look forward to hearing from you!</p>
                                        {/* <SubpagePricing /> */}
                                        <p><i><strong>Just as you need to breathe to survive, your love needs a breath of fresh air to flourish. Giving your relationship what it needs to thrive is a truly loving gesture.</strong></i></p><br/>
                                        <div className="center"><ScheduleAppointment /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <GoTop scrollStepInPx="50" delayInMs="16.66" />
            </React.Fragment>
        );
    }
}
 
export default Couples;