import React from 'react';
import clientImgOne from '../../assets/images/client-avatar1.jpg';
import clientImgTwo from '../../assets/images/client-avatar2.jpg';
import clientImgThree from '../../assets/images/client-avatar3.jpg';
 
class Testimonial extends React.Component {
    render(){
        return (
            <section id="testimonials" className="testimonials-area ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h2>Clients <span>Feedback</span></h2>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="single-feedback">

                                <p>"When I sought out therapy two years ago, I was experiencing sadness and depression like I had never felt before. I didn’t know how things could get better, but therapy with Mirela was the first step out of that darkness. Mirela offered a space of understanding, compassion, and healing when I was facing a difficult environment at school. I don’t think I would have been able to come out of the end of the school year with the same level of peace and calm I felt without Mirela’s guidance. The last two years that I’ve been in therapy with Mirela has been a year of discoveries, growth, and new beginnings. She encouraged me to step out of my comfort zone and become a better version of myself and I will always be grateful."</p>

                                <span><i className="fa fa-quote-left"></i></span>
                            </div>
                        </div>
                            
                        <div className="col-lg-12 col-md-12">
                            <div className="single-feedback">

                                <p>"Honestly, I didn't know what to expect going into therapy besides wanting to feel less overwhelmed daily. Mirela has helped me much beyond that - she has helped me develop the courage to step towards living a life true to myself. Her compassion and exceptional ability to create a safe space makes it possible to process challenging situations no matter how difficult. I have so much appreciation for her dedication to her clients and know there are many more benefits to come from working with her."</p>

                                <span><i className="fa fa-quote-right"></i></span>
                            </div>
                        </div>
                            
                        <div className="col-lg-12 col-md-12">
                            <div className="single-feedback">

                                <p>"Mirela has helped me to work through traumas in a productive way that has truly allowed me to make space for joy and growth in my life. Her insight has given me the useful tools needed to move forward past difficulties and to grow stronger from my experiences. I look forward to continuing to work with her through many new life chapters to come!"</p>

                                <span><i className="fa fa-quote-left"></i></span>
                            </div>
                        </div>
                        
                        <div className="col-lg-12 col-md-12">
                            <div className="single-feedback">

                                <p>"I have seen Mirela for approximately two years and I feel better than I have in a long time. She is thoughtful, kind, understanding and easy to talk to. Nothing I say makes her uncomfortable.  She shows great compassion.  I would recommend Mirela to anyone who needs long or short term treatment."</p>

                                <span><i className="fa fa-quote-left"></i></span>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="single-feedback">

                                <p>"I’ve been with Mirela for almost a year, and she has been one of the few great things to come out of this year. She has helped me process my erratic yet constantly invasive emotions and take inventory of the feelings I try to suppress. She has helped me become more connected to myself and my thoughts/feelings like I never have been before. She is so understanding and thoroughly listens to my various troubles. She is one of the first people in my life to truly listen and care about how I feel while also taking the time to understand what I'm going through. Mirela always knows the best way to go about things, and she creates a plan specific to your needs."</p>

                                <span><i className="fa fa-quote-left"></i></span>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="single-feedback">

                                <p>"I had seen a few other therapists before I found Mirela and I feel like none of them have understood how I felt like her. I struggle with anxiety and she helps me talk through situations or tough conversations that I need to have. This has helped me prepare for a lot and boosts my confidence in these situations. I recently started a new school and moved to a new city without knowing a lot of people. Mirela has helped me manage my school, work, and stay busy while still keeping time for myself. She encouraged me to speak my mind and helped me find my inner voice. I always look forward to our sessions."</p>

                                <span><i className="fa fa-quote-left"></i></span>
                            </div>
                        </div>
                            
                        <div className="col-lg-12 col-md-12">
                            <div className="single-feedback">

                                <p>"I'm a 23-year-old college student, and I've been working with Mirela for almost two years. In our time together, she has helped me grow in so many ways. She has a kind, thoughtful, and mindful approach to therapy. I have learned a lot about myself from her perspective, and look forward to our sessions every week!"</p>

                                <span><i className="fa fa-quote-right"></i></span>
                            </div>
                        </div>
                            
                        <div className="col-lg-12 col-md-12">
                            <div className="single-feedback">

                                <p>"I've worked with Mirela for over a year now and have really enjoyed our sessions. She is extremely patient, kind, knows when to explore a topic deeper, or let it be. She is always willing to offer additional resources and exercises on any topics. Thank you for your wisdom, guidance and healing!" </p>

                                <span><i className="fa fa-quote-left"></i></span>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div className="single-feedback">

                                <p>"Mirela has been perfect for me! I can say anything to her with no judgment and her warmth and care helps me move forward. She guides me with my problems, but lets me come to my own conclusions. It's great knowing I have someone to talk to that is impartial and since working with Mirela I've been able to sort through a lot of my problems that normally would get jumbled around in my head."</p>

                                <span><i className="fa fa-quote-left"></i></span>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div className="single-feedback">

                                <p>"We came in for couples therapy and had a wonderful experience. I highly recommend Mirela Bitkowski, she is very professional and helped us tremendously! Thank you again Mirela!"</p>

                                <span><i className="fa fa-quote-left"></i></span>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div className="single-feedback">

                                <p>"Thank you for all you have to done for my daughter, she is really coming out of her shell. She really likes you and feels connected. Thank you so much for all your support and guidance."</p>

                                <span><i className="fa fa-quote-left"></i></span>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div class="single-feedback">
                                <h6 style={{textAlign: "center", fontSize: "85%"}}>* All testimonials have been gathered anonymously to protect client privacy. *</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Testimonial;