import React from 'react';
import { Link } from 'react-router-dom';
import IndividualBanner from '../PageBanners/IndividualBanner';
import image from "../../assets/images/blog-details.jpg";
import clientImageOne from "../../assets/images/client-avatar1.jpg";
import clientImageTwo from "../../assets/images/client-avatar2.jpg";
import clientImageThree from "../../assets/images/client-avatar3.jpg";
import Footer from '../Common/Footer';
import GoTop from '../Common/GoTop';
import ScheduleAppointment from '../Common/ScheduleAppointment';
import Helmet from 'react-helmet';
import SubpagePricing from '../Common/SubpagePricing';
 
class Individual extends React.Component {
    render(){
        return (
            <React.Fragment>
                <Helmet>
                    <title>Insight Therapy Center | Individual Therapy | Counseling & Psychotherapy | Austin, TX | Mirela Bitkowski, LPC</title>
                </Helmet>
                <IndividualBanner />

                <section className="blog-details-area ptb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-12 offset-lg-2">
                                <div className="blog-details">
                                    <div className="article-text">
                                        <h4 className="title">Individual Therapy</h4>
                                        
                                        <p>Individual therapy, which is also known as talk therapy or counseling, focuses on your well-being and your unique needs and requirements. Some benefits of individual therapy include less anxiety, healthy coping skills, greater self-confidence, trauma resolution, better relationships, regaining and maintaining emotional balance, increased assertiveness, stress relief, ability to set boundaries, as well as many others. I will provide a safe, warm, non-judgemental, and compassionate environment where you can go deeper into your thoughts and feelings and explore barriers that interfere with your emotional and mental well-being.</p>
                                        
                                        <p>Typically, the first few therapy sessions are spent on clarifying the issues and examining what solutions have already been tried. Each session will involve discussions regarding situations that have troubled you in some way, how you felt during those moments, and what might have caused you to feel such emotions. It is often useful to think about what you would like to discuss during the counseling session and spend some time reflecting on your feelings and behaviors. The outcome of individual therapy depends on the unique needs and strengths of each person that is seeking assistance. As such, each therapy experience is unique, just as every individual is unique.</p>
                                        <p>When you're ready to begin individual therapy, fill out the information in "Schedule Now" and I will be contacting you soon to set up an appointment. I look forward to hearing from you!</p>
                                        <p><i><strong>The privilege of a lifetime is to become who you truly are.</strong> - Carl Jung</i></p><br/>
                                        {/* <SubpagePricing /> */}
                                        <div className="center"><ScheduleAppointment /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <GoTop scrollStepInPx="50" delayInMs="16.66" />
            </React.Fragment>
        );
    }
}
 
export default Individual;