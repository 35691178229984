import React from 'react';
import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import { Link } from 'react-router-dom';
import aboutImg from '../../assets/images/about.jpg';
 
class About extends React.Component {
    state = {
        isOpen: false
    }

    openModal = () => {
        this.setState({isOpen: true})
    }
    render(){
        return (
            <section id="about" className="about-area ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h2>My <span>Approach</span></h2>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="blog-details">
                            <div className="article-text">
                                    <p>My focus is to provide a safe, warm, and compassionate environment where you can go deeper into your thoughts and feelings and explore barriers that interfere with your relational, emotional, and mental well-being. I incorporate the <strong>latest evidence based, neuroscience-informed therapeutic modalities</strong> to help you achieve the desired results. I tailor different therapeutic modalities for each individual, couple and family, integrating various approaches as needed. In our sessions, we will address your immediate needs and also offer in-depth and long-term path to personal exploration, growth and healing.</p>
                                    <p>I also sometimes incorporate different experiential exercises, guided imagery, visualization and mindfulness  practices to help deepen your work, which will allow you be completely present with what is going on for you internally. My goal is to help you discover your own resiliency even in difficult moments, allowing you to navigate through life in a purposeful, authentic, and fulfilling way.</p>
                                    <p><strong>I am confident that we can work together to improve quickly and in profound ways.</strong> My work is supported by several degrees in <strong>Psychology and Counseling</strong>, and hundreds of hours of continued education in <strong>advanced psychotherapy training</strong>. I bring to every therapy session all the knowledge and tools that I have learned over the past eight years from my extensive psychology and mindfulness trainings. </p>
                                    <p>As a therapist, it is such a privilege and an honor to get to know people in therapy and see them <strong>make positive changes</strong> in their life, <strong>improve their relationships</strong> and <strong>self-esteem</strong>, as well as become <strong>more confident</strong> and <strong>self-reliant</strong>. I genuinely care about every individual, couple and family coming in my office and I deeply value the therapeutic relationship.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default About;