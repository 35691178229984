import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import ScheduleAppointment from '../Common/ScheduleAppointment';
 
class MainBanner extends React.Component {
    render(){
        return (
            <div id="home" className="main-banner item-bg-one">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="main-banner-text">
                                        <h1><span>Counseling</span> and <span>Therapy</span></h1>
                                        <h4>Adults • Children • Adolescents • Couples • Families</h4>
                                        {/* <br/>
                                        <h4><strong style={{color: "white"}}>* Accepting New Clients *</strong></h4> */}
                                        <br/>
                                        <h2>Improve Your <span>Well-Being</span></h2>
                                        <br/><br/>
                                        <ScheduleAppointment />
                                        {/* <AnchorLink href="#welcome" className="btn btn-primary">Get Started</AnchorLink> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default MainBanner;