import React from 'react';
import WaterWave from 'react-water-wave';
import { Link } from 'react-router-dom';
import image from '../../assets/images/main-bg-2.jpg';
 
class FamilyBanner extends React.Component {
    render(){
        return (
            <div id="home" className="main-banner item-bg-family">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="main-banner-text">
                                        <h1>Psychotherapy and Counseling</h1>
                                        <h4>Services: Children • Adolescents • Family Therapy</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default FamilyBanner;