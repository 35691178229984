import React from 'react';
import { Link } from 'react-router-dom';
 
class Footer extends React.Component {
    render(){
        return (
            <footer className="footer-area">
                
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <h4><Link to="/">Insight Therapy Center</Link></h4>
                            <ul>
                                <li><a href="https://www.facebook.com/austintxtherapy/" target="_blank" className="fa fa-facebook"></a></li>
                                <li><a href="https://www.instagram.com/atxtherapy/" target="_blank" className="fa fa-instagram"></a></li>
                                {/* <li><Link to="#" className="fa fa-linkedin"></Link></li> */}
                            </ul>
                            <p>Copyright <i className="fa fa-copyright"></i> All Rights Reserved.</p>
                        </div>
                    </div>
                </div>

                <div className="psychology-today">
                <a target="_blank" href="https://www.psychologytoday.com/profile/392437?tr=Ext_Verify" title="verified by Psychology Today">
					<img src="https://www.psychologytoday.com/profile/392437/verification.gif"
					  height="69" width="146"
					  alt="verified by Psychology Today"
					  border="0" /></a>
                </div>
            </footer>
        );
    }
}
 
export default Footer;