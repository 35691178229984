import React from 'react';
import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import '../../../node_modules/react-bootstrap-modal/lib/css/rbm-complete.css';
import { Link } from 'react-router-dom';
import videoImg from '../../assets/images/video-img.jpg';

var Modal = require('react-bootstrap-modal');
 
class Skill extends React.Component {
    state = {
        isOpen: false,
        formFields: {
            name: '',
            email: '',
            phone: '',
            text: ''
        }
    }

    
    onSubmit = (e) => {
        e.preventDefault();
        const data = this.state.formFields;
        fetch('/api/contact', {
            method: 'post',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => {
            if (res.status === 200) {
                this.setState({ submitted: true })
            }
            let formFields = Object.assign({}, this.state.formFields);
            formFields.name = '';
            formFields.email = '';
            formFields.phone = '';
            formFields.text = '';
            this.setState({formFields});
        });
    }

    nameChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.name = e.target.value;
        this.setState({formFields});
    }

    emailChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.email = e.target.value;
        this.setState({formFields});
    }

    phoneChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.phone = e.target.value;
        this.setState({formFields});
    }

    textChangeHandler = (e) => {
        let formFields = Object.assign({}, this.state.formFields);
        formFields.text = e.target.value;
        this.setState({formFields});
    }

    onHideSuccess = () => {
        this.setState({submitted: false});
    }

    successMessage = () => {
        if (this.state.submitted){
            return (
                <div 
                    className="alert alert-success alert-dismissible fade show" 
                    style={{ marginTop: '14px' }}
                >
                    <strong>Thank you!</strong> Your message is send to the owner.
                    <button 
                        type="button" 
                        className="close"
                        onClick={this.onHideSuccess}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            );
        }
    }

    render(){
        let closeModal = () => this.setState({ open: false });
        let openModal = () => this.setState({ open: true });

        return (
            <section className="skill-area ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-skill">
                                <div className="section-title">
                                    <h2>Some of Our <span>Skills</span></h2>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                                
                                <h3 className="progress-title">Branding</h3>
                                <div className="progress">
                                    <div className="progress-bar" style={{width: "92%"}}>
                                        <div className="progress-value">92%</div>
                                    </div>
                                </div>
                                
                                <h3 className="progress-title">Marketing</h3>
                                <div className="progress">
                                    <div className="progress-bar" style={{width: "97%"}}>
                                        <div className="progress-value">97%</div>
                                    </div>
                                </div>
                                
                                <h3 className="progress-title">Design</h3>
                                <div className="progress">
                                    <div className="progress-bar" style={{width: "90%"}}>
                                        <div className="progress-value">90%</div>
                                    </div>
                                </div>
                                
                                <h3 className="progress-title">Development</h3>
                                <div className="progress mb-0">
                                    <div className="progress-bar" style={{width: "95%"}}>
                                        <div className="progress-value">95%</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div className="col-lg-6 col-md-12">
                            <div className="skill-video">
                                <img src={videoImg} alt="video-img" />
                                <div className="video-btn">
                                    <button 
                                        onClick={openModal}
                                    >
                                        <i className="fa fa-play"></i>
                                    </button>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    show={this.state.open}
                    onHide={closeModal}
                    aria-labelledby="ModalHeader"
                    >
                    <Modal.Header>
                        <Modal.Title id='ModalHeader'>Schedule an Appointment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <form id="contactForm" onSubmit={this.onSubmit}>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="name">Name</label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    name="name" 
                                                    id="name" 
                                                    required={true}
                                                    data-error="Please enter your name" 
                                                    value={this.state.formFields.name}
                                                    onChange={this.nameChangeHandler}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="email">Email</label>
                                                <input 
                                                    type="email" 
                                                    className="form-control" 
                                                    name="email" 
                                                    id="email" 
                                                    required={true}
                                                    data-error="Please enter your email" 
                                                    value={this.state.formFields.email}
                                                    onChange={this.emailChangeHandler}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="number">Phone Number</label>
                                                <input 
                                                    type="text" 
                                                    className="form-control" 
                                                    name="number" 
                                                    id="number" 
                                                    required={true}
                                                    data-error="Please enter your number" 
                                                    value={this.state.formFields.phone}
                                                    onChange={this.phoneChangeHandler}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="message">Message</label>
                                                <textarea 
                                                    name="message" 
                                                    className="form-control" 
                                                    id="message" 
                                                    cols="30" 
                                                    rows="4" 
                                                    required={true}
                                                    data-error="Write your message" 
                                                    value={this.state.formFields.text}
                                                    onChange={this.textChangeHandler}
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <button type="submit" className="btn btn-primary">Send</button>
                                            <Modal.Dismiss className='btn btn-default'>Close</Modal.Dismiss>
                                            {this.successMessage()}
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </form>
                    </Modal.Body>
                </Modal>
            </section>
        );
    }
}
 
export default Skill;