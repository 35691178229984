import React from 'react'

class SubpagePricing extends React.Component {
    render(){
        return (
            <div>
                <h4>Fee &amp; Cancelation</h4><br/>
                <p>Fee is $140 for a 50-minute individual session.</p>
                <p>Please note that a 24-hour notice is required to cancel or change an appointment.</p>
                <br/>
            </div>
        );
    }
}
 
export default SubpagePricing;